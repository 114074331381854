import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const Logout = () => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();

    useEffect(() => {
        // if(!window.location.hostname.includes("localhost"))
        // {
        //     if (window.location.hostname === `${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}`) {
        //         window.location.replace(`https://www.${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}` + window.location.pathname  + window.location.search);
        //     }
        // }
        
        if(cookies.get('access_token'))
        {
            cookies.remove('access_token');
        }

        if(cookies.get('user'))
        {
            cookies.remove('user');
        }

        navigate('/');
    }, []);

    return (
        <>
            ระบบกำลังออกจากระบบให้คุณ
        </>
    )
}

export default Logout;