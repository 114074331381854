import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { ApiService } from "./services/api.service";
import { Box, Container } from "@mui/material";
import NavFooterMobile from "./components/Footer/NavFooterMobile";
import ScrollToTop from "./ScrollToTop";
import LoadingPage from "./components/Loading/LoadingPage";
import NewHeaderMain from "./components/NewHeader/NewHeaderMain";
import SocketIO from "./components/SocketIO";
import Swal from "sweetalert2";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies(null, { path: '/' });
    const [_loggedIn, setLoggedIn] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    // useEffect(() => {
    //     if(!cookies.get('alert'))
    //     {
    //         Swal.fire({
    //             icon: 'warning',
    //             title: 'แจ้งเตือน',
    //             text: 'ขณะนี้ปิดปรับปรุงระบบฝากผ่านบัญชีของเว็บไซต์ กรุณาฝากผ่านช่องทาง "พร้อมเพย์ QRCODE" เท่านั้นค่ะ'
    //         })
    //         cookies.set('alert', true)
    //     }
    // }, [])

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref);
        }

        // if(!window.location.hostname.includes("localhost"))
        // {
        //     if (window.location.hostname === `${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}`) {
        //         window.location.replace(`https://www.${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}` + window.location.pathname  + window.location.search);
        //     }
        // }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const pathname = location.pathname.split("/")[1];

                setLoaded(false);
                if(!cookies.get('access_token'))
                {
                    if(pathname === 'member')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'memberinfo')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'deposit')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'withdraw')
                    {
                        navigate('/login');
                    }

                    setLoggedIn(false);
                    setLoaded(true);
                    return;
                }
        
                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    cookies.remove('access_token');
                    setLoggedIn(false);
                    
                    if(pathname === 'member')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'memberinfo')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'deposit')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'withdraw')
                    {
                        navigate('/login');
                    }
                }
                else if(checkToken.status_code === 200)
                {
                    setLoggedIn(true);
                }

                setLoaded(true);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [location]);

    return (
        loaded ? <>
            {/* <Header /> */}
            {/* <Container> */}
            <Container sx={
                {
                    maxWidth: '768px !important',
                    padding: '0 !important'
                }
            }>
                <NewHeaderMain />
                <Box sx={
                    {
                        backgroundColor: 'rgba(0, 0, 0, .15)',
                        minHeight: `100dvh`,
                        height: '100%',
                        mb: 0,
                        pb: 0
                    }
                }>
                    {/* <Box sx={
                        {
                            backgroundColor: '#003b10',
                            px: 2,
                            py: .5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }>
                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`/`} style={{ color: 'white' }}>
                                <img src={Logo} alt="" style={{ width: `auto`, maxHeight: 55 }} />
                            </Link>
                        </Box>
                        <Box></Box>
                        <Header />
                    </Box> */}
                    <Box sx={
                        {
                            display: `flex`,
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }
                    }>
                        <Box px={2} py={2}>
                            <Outlet/>
                        </Box>
                    </Box>
                </Box>
            </Container>
            {/* </Container> */}
            {/* <Footer /> */}
            <NavFooterMobile />
            <ScrollToTop />
            <SocketIO />
        </> : <LoadingPage />
    )
}

export default App;