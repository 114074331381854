import { useRef, useState, MouseEvent, useEffect } from "react";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Logo from './../../assets/images/logo-circle.png';

import { Box, Container, Typography, Button, Grid, FormControl, FormLabel, OutlinedInput, InputAdornment, IconButton, MenuItem, Select, Divider, CircularProgress } from "@mui/material";
import { FaLine } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Swal from "sweetalert2";

const validate_password = (input: string) => {
    return input.includes(' ') ? false : true;
}

const checkStringPassword = (inputString: string) => {
    // เช็คว่ามีตัวอักษรภาษาอังกฤษตัวใหญ่หรือไม่
    const hasUpperCase = /[A-Z]/.test(inputString);
    
    // เช็คว่ามีตัวอักษรภาษาอังกฤษตัวเล็กหรือไม่
    const hasLowerCase = /[a-z]/.test(inputString);
    
    // เช็คว่ามีตัวเลขหรือไม่
    const hasNumber = /\d/.test(inputString);
    
    // ตรวจสอบว่ามีทั้งหมดหรือไม่
    if (hasUpperCase && hasLowerCase && hasNumber) {
        return true;
    } else {
        return false;
    }
}

const isNumericString = (str: string) => {
    return /^\d+$/.test(str);
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.bullpay.me/images/logo/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.bullpay.me/images/logo/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.bullpay.me/images/logo/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.bullpay.me/images/logo/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.bullpay.me/images/logo/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    // {
    //     "bank_abbr": "TMW",
    //     "bank_code": "099",
    //     "bank_logo": "https://cdn.bullpay.me/images/logo/turemoney.png",
    //     "bank_name_en": "True Money Wallet",
    //     "bank_name_th": "ทรูมันนี่วอลเล็ท"
    // }
];

const RegisterMain = () => {
    const query = useQuery();
    const by = query.get('by');
    const aff = query.get('aff');
    const cookies = new Cookies(null, { path: '/' });
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [regBy, setRegBy] = useState<string | null>(null);
    const [affBy, setAffBy] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfirmRef = useRef<HTMLInputElement>(null);
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const lineRef = useRef<HTMLInputElement>(null);
    const bankRef = useRef<HTMLSelectElement>(null);
    const bankNumberRef = useRef<HTMLInputElement>(null);
    // const knowAtRef = useRef<HTMLSelectElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault();

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref);
        }

        // if(!window.location.hostname.includes("localhost"))
        // {
        //     if (window.location.hostname === `${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}`) {
        //         window.location.replace(`https://www.${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}` + window.location.pathname  + window.location.search);
        //     }
        // }
    }, []);

    useEffect(() => {
        if(by)
        {
            cookies.set('reg_by', by);
            setRegBy(by);

            addClick(by);
        }
        else
        {
            setRegBy(!cookies.get('reg_by') ? null : cookies.get('reg_by'));
            if(cookies.get('reg_by'))
            {
                addClick(cookies.get('reg_by'));
            }
        }
    }, [by]);

    useEffect(() => {
        if(aff)
        {
            cookies.set('aff', aff);
            setAffBy(aff);
        }
        else
        {
            setAffBy(!cookies.get('aff') ? null : cookies.get('aff'));
        }
    }, [aff]);

    const addClick = async (reg_by: string) => {
        try {
            const service = new ApiService();
            await service.addClickCount(reg_by);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if(!cookies.get('access_token'))
                {
                    setLoaded(true);
                    return;
                }

                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    setLoaded(true);
                    return cookies.remove('access_token');
                }
                else if(checkToken.status_code === 200)
                {
                    return navigate('/');
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const send_register = async () => {
        if(!usernameRef.current || !passwordRef.current || !passwordRef.current || !passwordConfirmRef.current || !firstnameRef.current || !lastnameRef.current || !lineRef.current || !bankRef.current || !bankNumberRef.current)
        {
            return;
        }

        const phone = usernameRef.current.value.trim();
        const password = passwordRef.current.value.trim();
        const passwordConfirm = passwordConfirmRef.current.value.trim();
        const first_name = firstnameRef.current.value.trim();
        const last_name = lastnameRef.current.value.trim();
        const line = lineRef.current.value.trim();
        const know_at = '';
        const bank = bankRef.current.value.trim();
        const bank_number = bankNumberRef.current.value.trim();

        if(phone === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เบอร์โทรศัพท์ไม่สามารถเว้นว่างได้'
            });
        }
        else if(password === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
            });
        }
        else if(password !== passwordConfirm)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านไม่ตรงกัน กรุณากรอกใหม่อีกครั้ง'
            });
        }
        else if(first_name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ชื่อจริงไม่สามารถเว้นว่างได้'
            });
        }
        else if(last_name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'นามสกุลไม่สามารถเว้นว่างได้'
            });
        }
        else if(bank_number === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เลขบัญชีธนาคารไม่สามารถเว้นว่างได้'
            });
        }
        else if(!validate_password(password))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านห้ามมีเว้นวรรค'
            });
        }
        // else if(!checkStringPassword(password))
        // {
        //     return Swal.fire({
        //         icon: 'error',
        //         title: 'แจ้งเตือน',
        //         text: 'รหัสผ่านจำเป็นต้องมีตัวอักษรตัวเล็ก ตัวใหญ่ และ ตัวเลข'
        //     });
        // }
        else if(password.length < 8 || password.length > 15)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านจำเป็นต้องมี 8-15 ตัวอักษรเท่านั้น'
            });
        }
        else if(!(isNumericString(bank_number)))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เลขบัญชีจำเป็นต้องกรอกเฉพาะตัวเลขเท่านั้นค่ะ (ไม่ต้องมี - หรือ เว้นวรรค)'
            });
        }
        
        try {
            setLoading(true);
            const service = new ApiService();
            const register = await service.register({
                password: password,
                phone: phone,
                first_name: first_name,
                last_name: last_name,
                line: line,
                know_at: know_at,
                bank: bank,
                bank_number: bank_number,
                by: regBy,
                aff: affBy
            });

            setLoading(false);
            if(register.data.status_code === 200 && register.data.message === 'Success')
            {
                cookies.set('access_token', register.data.data.token);
                cookies.set('user', register.data.data.ufa_username);
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    // text: `Username: ${phone}<br>Password: ${password}`,
                    html: `สมัครสมาชิกเรียบร้อยแล้วค่ะ<br/>Username: <b>${phone}</b><br/>Password: <b>${password}</b>`
                }).then(() => {
                    navigate('/');
                });
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: register.data.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ กรุณาลองใหม่อีกครั้ง"
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <Container sx={
                {
                    maxWidth: '600px !important',
                    padding: '0 !important'
                }
            }>
                <Box sx={
                    {
                        backgroundColor: 'rgba(0, 0, 0, .4)',
                        minHeight: `100dvh`,
                        height: '100%',
                        mb: 0,
                        pb: 0
                    }
                }>
                    <Box sx={
                        {
                            backgroundColor: '#750000',
                            px: 2,
                            py: 2.5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }>
                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`/login`} style={{ color: 'white' }}>
                                <Typography display={`flex`} justifyContent={`center`} alignContent={`center`}>
                                    <KeyboardArrowLeftIcon />กลับไปเข้าสู่ระบบ
                                </Typography>
                            </Link>
                        </Box>
                        <Box></Box>
                        <Box display={`flex`} justifyContent={`flex-end`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`https://lin.ee/h3vvUJ2`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaLine color="#04c854" />&nbsp;LINE@
                                </Button>
                            </Link>
                            <Link to={`https://t.me/+r9iAShZ-PCMzYTk1`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaTelegram color="#34aae4" />&nbsp;TELEGRAM
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Box px={4} py={2}>
                        <Grid container spacing={2} sx={{ mt: .5 }}>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <img src={Logo} alt="Logo" style={{ width: '100%', maxWidth: 250 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontSize={20} align="center">
                                    สมัครสมาชิก
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_bank">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            ธนาคาร
                                        </Typography>
                                    </FormLabel>
                                    <Select
                                        fullWidth
                                        inputRef={bankRef}
                                        sx={
                                            {
                                                backgroundColor: 'white',
                                            }
                                        }
                                        defaultValue={`SCB`}
                                    >
                                        {
                                            bank_list.map((element) => {
                                                return (
                                                    <MenuItem value={element.bank_abbr}>
                                                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                                                            <img src={element.bank_logo} alt={`bank_logo_${element.bank_abbr}`} style={{ maxWidth: 20 }} />
                                                            &nbsp;
                                                            {element.bank_name_th}
                                                        </Box>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography color={`#e0e0e0`} fontSize={13} mt={1} mb={-1}>
                                    ธนาคารที่ง่ายต่อการตรวจสอบ: กสิกรไทย (KBANK), ไทยพาณิชย์ (SCB)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_bank_account">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            เลขที่บัญชี
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_bank_account'
                                        type={`text`}
                                        fullWidth
                                        autoComplete="user_bank_account"
                                        placeholder="เลขที่บัญชี"
                                        inputRef={bankNumberRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_firstname">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            ชื่อจริง
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_firstname'
                                        type={`text`}
                                        fullWidth
                                        autoComplete="user_firstname"
                                        placeholder="ชื่อจริง"
                                        inputRef={firstnameRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_lastname">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            นามสกุล
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_lastname'
                                        type={`text`}
                                        fullWidth
                                        autoComplete="user_lastname"
                                        placeholder="นามสกุล"
                                        inputRef={lastnameRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{
                                    borderColor: 'white'
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_phone">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            เบอร์โทรศัพท์
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_phone'
                                        type={`text`}
                                        fullWidth
                                        autoComplete="user_phone"
                                        placeholder="หมายเลขเบอร์โทรศัพท์ 10 หลักสำหรับเข้าสู่ระบบ"
                                        inputRef={usernameRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_password">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            รหัสผ่าน
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_password'
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {
                                                        showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        // size='small'
                                        fullWidth
                                        autoComplete="user_password"
                                        placeholder="รหัสผ่าน"
                                        inputRef={passwordRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_confirm_password">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            ยืนยันรหัสผ่าน
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_confirm_password'
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {
                                                        showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        fullWidth
                                        autoComplete="user_confirm_password"
                                        placeholder="ยืนยันรหัสผ่าน"
                                        inputRef={passwordConfirmRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Divider sx={{
                                    "&::before, &::after": {
                                        borderColor: "white",
                                    },
                                }}>
                                    ข้อมูลธนาคาร
                                </Divider>
                            </Grid> */}
                            
                            <Grid item xs={12} sx={{ display: 'none' }}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_line">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            ไลน์ไอดี (ไม่จำเป็น)
                                        </Typography>
                                    </FormLabel>
                                    <OutlinedInput
                                        name='user_line'
                                        type={`text`}
                                        // size='small'
                                        fullWidth
                                        autoComplete="user_line"
                                        placeholder="ไลน์ไอดี"
                                        inputRef={lineRef}
                                        sx={
                                            {
                                                backgroundColor: 'white'
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Divider sx={{
                                    "&::before, &::after": {
                                        borderColor: "white",
                                    },
                                }}>
                                    ข้อมูลที่เกี่ยวข้อง
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="user_knowat">
                                        <Typography color={`white`} fontSize={17} fontWeight={600}>
                                            รู้จักเราจากช่องทาง
                                        </Typography>
                                    </FormLabel>
                                    <Select
                                        fullWidth
                                        // size="small"
                                        // onChange={(e) => {
                                        //     handleChooseServer(e.target.value)
                                        // }}
                                        inputRef={knowAtRef}
                                        sx={
                                            {
                                                backgroundColor: 'white',
                                            }
                                        }
                                        defaultValue={`line`}
                                    >
                                        <MenuItem value="line">LINE</MenuItem>
                                        <MenuItem value="facebook">FACEBOOK</MenuItem>
                                        <MenuItem value="google">Google</MenuItem>
                                        <MenuItem value="youtube">Youtube</MenuItem>
                                        <MenuItem value="instagram">Instagram</MenuItem>
                                        <MenuItem value="tiktok">Tiktok</MenuItem>
                                        <MenuItem value="twitter">Twitter</MenuItem>
                                        <MenuItem value="sms">SMS</MenuItem>
                                        <MenuItem value="other_website_or_friend">มาจากเว็บไซต์อื่น / เพื่อนแนะนำ</MenuItem>
                                        <MenuItem value="zean">เซียน</MenuItem>
                                        <MenuItem value="ads">โฆษณาอื่นๆ</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Button fullWidth sx={
                                    {
                                        py: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                            color: 'black',
                                        }
                                    }
                                } onClick={send_register} disabled={loading}>
                                    <Typography fontSize={16} fontWeight={600}>
                                        {
                                            loading ? <CircularProgress size={24} sx={
                                                {
                                                    color: 'white'
                                                }
                                            } /> : "สมัครสมาชิก"
                                        }
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontSize={14}>
                                    หมายเหตุ
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography fontSize={14}>
                                            เพื่อความรวดเร็วและความถูกต้องในการตรวจสอบธุรกรรม โปรดระบุข้อมูลธนาคาร เลขบัญชี ชื่อ สกุล ให้ถูกต้องและเป็นไปตามจริง
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            เราจะเก็บรักษาข้อมูลส่วนตัวของผู้ใช้งานเป็นความลับ และจะไม่มีการ นำข้อมูลดังกล่าวไปใช้เพื่อการค้าอื่น
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default RegisterMain;