import { useState, useEffect, FormEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";

import { Box, Container, Typography, Button, Grid, FormControl, FormLabel, OutlinedInput, InputAdornment, IconButton, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import { FaLine } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";

import Logo from './../../assets/images/logo-circle.png';

const ReturnCredit = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [loseTotal, setLoseTotal] = useState<number>(0);
    const [returnCreditTotal, setReturnCreditTotal] = useState<number>(0);

    const getReturnWinloseCredit = async (e: FormEvent) => {
        e.preventDefault();
        if(loading)
        {
            return;
        }
        setLoading(true);
        try {
            const service = new ApiService();
            const getReturnCredit = await service.getReturnWinloseCredit(cookies.get('access_token'));

            if(getReturnCredit.data.status_code !== 200)
            {
                setLoading(false);
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: getReturnCredit.data.message || "ไม่ทราบสาเหตุข้อผิดพลาด"
                });
            }

            Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                text: `รับยอดเสียคืนเรียบร้อยแล้ว จำนวน ${parseFloat(String(getReturnCredit.data.returnCreditTotal)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} เครดิต`
            }).then(() => {
                navigate('/');
            });
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
        setLoaded(false);
        getReturnWinloseCreditData();
    }

    const getReturnWinloseCreditData = async () => {
        try {
            const service = new ApiService();
            const getData = await service.getReturnWinloseCreditData(cookies.get('access_token'));

            if(getData.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: getData.data.message || "ไม่ทราบสาเหตุข้อผิดพลาด"
                }).then(() => {
                    navigate('/');
                });
            }

            setLoseTotal(getData.data.loseTotal);
            setReturnCreditTotal(getData.data.returnCreditTotal);
            setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        // if(!window.location.hostname.includes("localhost"))
        // {
        //     if (window.location.hostname === `${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}`) {
        //         window.location.replace(`https://www.${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}` + window.location.pathname  + window.location.search);
        //     }
        // }

        if(!cookies.get('access_token'))
        {
            return navigate('/login');
        }

        getReturnWinloseCreditData();
    }, []);

    return (
        loaded ? <Container sx={
                {
                    maxWidth: '600px !important',
                    padding: '0 !important'
                }
            }>
                <Box sx={
                    {
                        backgroundColor: 'rgba(0, 0, 0, .15)',
                        minHeight: `100dvh`,
                        height: '100%',
                        mb: 0,
                        pb: 0
                    }
                }>
                    <Box sx={
                        {
                            backgroundColor: '#750000',
                            px: 2,
                            py: .5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }>
                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`/`} style={{ color: 'white' }}>
                                <img src={Logo} alt="" style={{ width: `auto`, maxHeight: 55 }} />
                            </Link>
                        </Box>
                        <Box></Box>
                        <Box display={`flex`} justifyContent={`flex-end`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`https://lin.ee/h3vvUJ2`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaLine color="#04c854" />&nbsp;LINE@
                                </Button>
                            </Link>
                            <Link to={`https://t.me/+r9iAShZ-PCMzYTk1`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaTelegram color="#34aae4" />&nbsp;TELEGRAM
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <form name="user_get_return_wl_credit" id="user_get_return_wl_credit" onSubmit={getReturnWinloseCredit}>
                        <Box sx={
                            {
                                display: `flex`,
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                height: '90dvh'
                            }
                        }>
                            <Box px={4} py={2}>
                                <Typography fontSize={20} align="center">
                                    คืนยอดเสีย 5% ทุกวันจันทร์
                                </Typography>
                                <Grid container spacing={2} sx={{ mt: .5 }}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="user_credit_winlose_total">
                                                <Typography color={`white`} fontSize={13}>
                                                    ยอดเสียทั้งหมดที่ยังไม่ได้รับ
                                                </Typography>
                                            </FormLabel>
                                            <OutlinedInput
                                                name='user_credit_winlose_total'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                autoComplete="user_credit_winlose_total"
                                                sx={
                                                    {
                                                        backgroundColor: 'white'
                                                    }
                                                }
                                                readOnly
                                                value={loseTotal.toFixed(2)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="user_credit_return_total">
                                                <Typography color={`white`} fontSize={13}>
                                                    ยอดเสียที่จะได้รับคืน (5% ของยอดเสีย)
                                                </Typography>
                                            </FormLabel>
                                            <OutlinedInput
                                                name='user_credit_return_total'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                autoComplete="user_credit_return_total"
                                                sx={
                                                    {
                                                        backgroundColor: 'white'
                                                    }
                                                }
                                                readOnly
                                                value={returnCreditTotal.toFixed(2)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={14}>
                                            หมายเหตุ
                                        </Typography>
                                        <ul>
                                         <li>
                                                <Typography fontSize={14}>
                                                    สามารถรับยอดเสียคืนของแต่ละสัปดาห์ได้แค่วันจันทร์เท่านั้น
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    สามารถรับยอดเสียคืนได้เพียง 5% เท่านั้น
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ยอดเสียที่นำมาคำนวณ คือ ยอดเสียที่ถูกเก็บไว้เป็นวันต่อวัน
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ยอดเสียที่นำมาคำนวณ คือ ยอดเสียของสัปดาห์ที่แล้ว
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ไม่สามารถรับยอดเสียคืนย้อนหลังได้
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography fontSize={14}>
                                                    ยอดเสียที่จะได้รับคืนจะถูกตัดทศนิยมออก
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box px={2}>
                                <Grid container>
                                    <Grid item xs={12} mb={2}>
                                        <Button type="submit" fullWidth sx={
                                            {
                                                py: 2,
                                                backgroundColor: '#f4ce5c',
                                                color: 'black',
                                                '&:hover': {
                                                    backgroundColor: '#e2be55',
                                                    color: 'black',
                                                }
                                            }
                                        } disabled={loading}>
                                            <Typography fontSize={16} fontWeight={600}>
                                                {
                                                    loading ? <CircularProgress size={24} sx={
                                                        {
                                                            color: 'white'
                                                        }
                                                    } /> : "รับยอดเสียคืน"
                                                }
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Container>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default ReturnCredit;