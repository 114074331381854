import { useRef, useState, MouseEvent, ChangeEvent, useEffect, FormEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";

import { Box, Container, Typography, Button, Grid, FormControl, FormLabel, OutlinedInput, InputAdornment, IconButton, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { FaLine } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Logo from './../../assets/images/logo-circle.png';

const LoginMain = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault();
    const handleChangeRememberMe = (e: ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked);

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref);
        }

        // if(!window.location.hostname.includes("localhost"))
        // {
        //     if (window.location.hostname === `${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}`) {
        //         window.location.replace(`https://www.${process.env.REACT_APP_BASE_URL?.replace("https://", '').replace('http://', '')}` + window.location.pathname  + window.location.search);
        //     }
        // }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if(!cookies.get('access_token'))
                {
                    setLoaded(true);
                    return;
                }

                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    setLoaded(true);
                    return cookies.remove('access_token');
                }
                else if(checkToken.status_code === 200)
                {
                    return navigate('/game/slot');
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const send_login = async (e: FormEvent) => {
        e.preventDefault();
        
        try {
            if(!usernameRef.current || !passwordRef.current)
            {
                return;
            }

            const phone = usernameRef.current.value.trim();
            const password = passwordRef.current.value.trim();

            if(phone === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ชื่อผู้ใช้งานไม่สามารถเว้นว่างได้'
                });
            }
            else if(password === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
                });
            }

            setLoading(true);
            const service = new ApiService();
            const login = await service.login({
                phone,
                password,
                rememberMe
            });
            const { data } = login;
            setLoading(false);

            if(data.status_code === 200 && data.message === 'Success')
            {
                const expires = new Date();
                expires.setDate(expires.getDate() + (rememberMe ? 7 : 1));

                cookies.set('access_token', data.member_data.access_token, { expires });
                cookies.set('user', data.member_data.user, { expires });
                return navigate('/game/slot');
            }

            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: data.message || 'ไม่ทราบสาเหตุข้อผิดพลาด'
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <Container sx={
                {
                    maxWidth: '600px !important',
                    padding: '0 !important'
                }
            }>
                <Box sx={
                    {
                        backgroundColor: 'rgba(0, 0, 0, .15)',
                        minHeight: `100dvh`,
                        height: '100%',
                        mb: 0,
                        pb: 0
                    }
                }>
                    <Box sx={
                        {
                            backgroundColor: '#750000',
                            px: 2,
                            py: .5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }>
                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`/`} style={{ color: 'white' }}>
                                <img src={Logo} alt="" style={{ width: `auto`, maxHeight: 55 }} />
                            </Link>
                        </Box>
                        <Box></Box>
                        <Box display={`flex`} justifyContent={`flex-end`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`https://lin.ee/h3vvUJ2`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaLine color="#04c854" />&nbsp;LINE@
                                </Button>
                            </Link>
                            <Link to={`https://t.me/+r9iAShZ-PCMzYTk1`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaTelegram color="#34aae4" />&nbsp;TELEGRAM
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <form name="user_login" id="user_login" onSubmit={send_login}>
                        <Box sx={
                            {
                                display: `flex`,
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                height: '90dvh'
                            }
                        }>
                            <Box px={4} py={2}>
                                <Typography fontSize={20} align="center">
                                    เข้าสู่ระบบ
                                </Typography>
                                <Grid container spacing={2} sx={{ mt: .5 }}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="user_phone">
                                                <Typography color={`white`} fontSize={13}>
                                                    ชื่อผู้ใช้
                                                </Typography>
                                            </FormLabel>
                                            <OutlinedInput
                                                name='user_phone'
                                                type={`tel`}
                                                size='small'
                                                fullWidth
                                                autoComplete="user_phone"
                                                placeholder="ชื่อผู้ใช้"
                                                inputRef={usernameRef}
                                                sx={
                                                    {
                                                        backgroundColor: 'white'
                                                    }
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="user_password">
                                                <Typography color={`white`} fontSize={13}>
                                                    รหัสผ่าน
                                                </Typography>
                                            </FormLabel>
                                            <OutlinedInput
                                                name='user_password'
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {
                                                                showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                size='small'
                                                fullWidth
                                                autoComplete="user_password"
                                                placeholder="รหัสผ่าน"
                                                inputRef={passwordRef}
                                                sx={
                                                    {
                                                        backgroundColor: 'white'
                                                    }
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel control={
                                            <Checkbox sx={
                                                {
                                                    py: 0,
                                                    color: 'white',
                                                    '& .MuiSvgIcon-root': {
                                                        fill: 'white',
                                                        stroke: 'white',
                                                    },
                                                    '&.Mui-checked .MuiSvgIcon-root': {
                                                        fill: '#f4ce5c',
                                                        stroke: '#f4ce5c',
                                                    }
                                                }
                                            } defaultChecked={true} onChange={handleChangeRememberMe} />
                                        } label="จดจำรหัสผ่าน" />
                                    </Grid>
                                    <Grid item xs={6} display={`flex`} justifyContent={`flex-end`}>
                                        <Link to={'/'} style={{ color: 'white', textDecoration: 'underline' }}>
                                            ลืมรหัสผ่าน ?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box px={2}>
                                <Grid container>
                                    <Grid item xs={12} mb={2}>
                                        <Button type="submit" fullWidth sx={
                                            {
                                                py: 2,
                                                backgroundColor: '#f4ce5c',
                                                color: 'black',
                                                '&:hover': {
                                                    backgroundColor: '#e2be55',
                                                    color: 'black',
                                                }
                                            }
                                        } disabled={loading}>
                                            <Typography fontSize={16} fontWeight={600}>
                                                {
                                                    loading ? <CircularProgress size={24} sx={
                                                        {
                                                            color: 'white'
                                                        }
                                                    } /> : "เข้าสู่ระบบ"
                                                }
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} mb={2} display={`flex`} justifyContent={`center`}>
                                        หรือ
                                    </Grid>
                                    <Grid item xs={12} mb={3}>
                                        <Button fullWidth sx={
                                            {
                                                py: 2,
                                                backgroundColor: 'transparent',
                                                color: '#f4ce5c',
                                                border: 'solid 1px #f4ce5c',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, .05)',
                                                    color: '#f4ce5c',
                                                }
                                            }
                                        } onClick={() => navigate('/register')}>
                                            <Typography fontSize={16} fontWeight={600}>
                                                สมัครสมาชิก
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Container>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default LoginMain;